import React, { useState, useEffect } from "react";

export const LeyAngelBanner: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${
        scrolled ? "fixed top-12 right-2" : "hidden"
      } text-white text-center py-4 px-4 rounded-lg mt-10 lg:max-w-7xl transition-all duration-300 z-50 bg-gradient-to-r from-green-400 to-green-700 shadow-lg`}
    >
      Apoya la Ley Ángel -{" "}
      <a href="/leyangel" className="text-yellow-300 font-bold underline">
        Firma aquí
      </a>
    </div>
  );
};
